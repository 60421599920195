import "./sass/style.scss";
import "normalize.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min.js";
import axios from "axios";
import { initializeApp } from "firebase/app";

const nameInput = document.getElementById("name");
const emailInput = document.getElementById("email");
const phoneInput = document.getElementById("phone");
const messageInput = document.getElementById("message");
const selectInput = document.querySelector("#form select");
const formSubmitter = document.getElementById("form-submitter");
const form = document.getElementById("form");

console.log(form);
let apiUrl = "https://massar-send-mails-api.onrender.com/api/send-mail";

form.onsubmit = (e) => {
  e.preventDefault();
};
const formAlert = document.querySelector("#form-feedback");
formSubmitter.onclick = () => {
  formAlert.classList.add("show");
  axios
    .post(apiUrl, {
      name: nameInput.value,
      email: emailInput.value,
      phone: phoneInput.value,
      message: messageInput.value,
      country: selectInput.value,
    })
    .then(handleSent);
};

function handleSent() {
  const formFeedback = document.querySelector("#form-feedback h2");
  formFeedback.innerText = "شكرا لك! سيقوم أحد مستشارينا العقاريين بالتواصل معك في أقرب وقت";

  setTimeout(() => {
    formAlert.classList.remove("show");
  }, 1500);
  setTimeout(() => {
    window.location.reload();
  }, 2000);

  return;
}

const firebaseConfig = {
  apiKey: "AIzaSyC_437RSA2Q1lGKFzgUrACPx7f-s8hyqyA",
  authDomain: "massar-project-18668.firebaseapp.com",
  projectId: "massar-project-18668",
  storageBucket: "massar-project-18668.appspot.com",
  messagingSenderId: "1069701186194",
  appId: "1:1069701186194:web:852b9fa38447f2c4551c4b",
  measurementId: "G-KQFLB86QWL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
